import React, { Fragment } from 'react'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import classNames from 'classnames'

import SEO from '../../components/Seo'
import RelatedItems from '../../components/RelatedItems/RelatedItems'
import ContactUs from '../../components/Contactus/Contactus'
import Icon from '../../components/UI/Icon/Icon'
import { mergeArrays } from '../../utils/mergeArrays'
import { slugify } from '../../utils/utilityFunctions'

const softwareDevelopmentOutsourcing = ({ data }) => {
  const {
    service: {
      frontmatter: {
        title,
        descriptions,
        image,
        approaches,
        benefits,
        advantages: {
          header,
          list: advantagesList
        },
        interestingForYou
      }
    },
    relatedItems: { edges },
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
  } = data

  /* added order to related item, will use it to
    show correct orders on Intresting For You section */
  const itemsList = interestingForYou
    .map((item, index) => Object.assign({ order: index }, item))

  // created array of objects which will be shown on Interesting for you section
  const intrestingForYouContent = mergeArrays(edges, itemsList)

  return (
    <Fragment>
      <SEO data={ seoInfo } />
      <div className="software-development-outsourcing">
        <section className="header">
          <h1 className="header__title">{title}</h1>
        </section>
        <section className="description">
          {descriptions.map((description, index) => (
            <div
              key={index}
              className={classNames(
                'description__item',
                { 'description__item--last': descriptions.length - 1 === index }
              )}>
              <h2 className="description__title">{description.title}</h2>
              <div
                className="description__text"
                dangerouslySetInnerHTML={{ __html: description.text }}
              />
            </div>
          ))}
          <Img
            className="descriptions__image"
            fluid={image.childImageSharp.fluid}
            alt={title} />
        </section>
        <section className="approaches">
          <div className="approaches__list">
            {approaches.map((approache, index) => (
              <div key={index} className="approaches__item">
                <Icon type={slugify(approache.title)} />
                <h2 className="approaches__title">{approache.title}</h2>
                <p className="approaches__text">{approache.text}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="benefits">
          <h2 className="benefits__header">Benefits of Software Outsourcing</h2>
          <div className="benefits__list">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className={classNames(
                  'benefits__item',
                  { 'benefits__item--last': benefits.length - 1 === index }
                )}>
                <h2 className="benefits__title">{benefit.title}</h2>
                <p className="benefits__text">{benefit.text}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="contact">
          <div className="contact__content">
            <p className="contact__text">Let's turn your idea into reality</p>
            <Link to="/contact-us/" className="contact__button">Talk to us</Link>
          </div>
        </section>
        <section className="advantages">
          <h1 className="advantages__header">{header.title}</h1>
          <p className="advantages__subheader">{header.subtitle}</p>
          <div className="advantages__list">
            {advantagesList.map((advantage, index) => (
              <div key={index} className={classNames(
                'advantages__item',
                { 'advantages__item--last': index > advantagesList.length - 3 }
              )}>
                <h2 className="advantages__title">{advantage.title}</h2>
                <p className="advantages__text">{advantage.text}</p>
              </div>
            ))}
          </div>
        </section>
        <ContactUs
          contactUsDescription={''}
          contactUsSlogan={'Ready to know more about Bluepes?'}
          buttonName={'Contact us '}
        />
        <RelatedItems
          label={'Interesting for you'}
          relatedItems={intrestingForYouContent}
        />
      </div>
    </Fragment>
  )
}

export default softwareDevelopmentOutsourcing

export const data = graphql`
  query ($slug: String!, $relatedItemsTitle: [String]!) {
    service: markdownRemark(
      fields: { slug: { eq: $slug }} 
      fileAbsolutePath: { regex: "/content/individual-pages/new-services/" }
    ) {
      frontmatter {
        title
        descriptions {
          text
          title
        }
        image {
          childImageSharp {
            fluid (quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        approaches {
          text
          title
        }
        benefits {
          text
          title
        }
        advantages {
          header {
            subtitle
            title
          }
          list {
            text
            title
          }
        }
        interestingForYou {
          name
          description
          image {
            childImageSharp {
              fixed (width: 394, height: 237, quality: 100) {
                src
              }
            }
          }
          type
        }
      }
    }
    seo: markdownRemark(
      fields: { slug: { eq: $slug }}
      fileAbsolutePath: { regex: "/content/seo/services/" }
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    relatedItems: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { permalink: { in: $relatedItemsTitle }}
        fileAbsolutePath: { regex: "/content/individual-pages/"}
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            permalink
            title
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            whatWeDo
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fixed (width: 394, height: 237, quality: 100){
                  src
                }
              }
            }
            description
          }
          fields {
            slug
            type
          }
        }
      }
    }
  }
`
